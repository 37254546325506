import React from "react";
import { Link } from "react-router-dom";
import bg from "../images/bg.jpg";
import logo from "../images/logo.png";
import "../Admin/style.css"

export default function Login() {
  return (
    <div className="login-page" style={{ backgroundImage: `url(${bg})` }}>
      <div className="login-from-wrap">
        <form className="login-form">
          <h1 className="site-title">
            <Link to="#">
              <img src={logo} alt="logo" />
            </Link>
          </h1>
          <div className="form-group">
            <label htmlFor="user_name">User Name</label>
            <input type="text" id="user_name" className="validate" />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input id="password" type="password" className="validate" />
          </div>
          <div className="form-group">
            <Link className="button-primary" to="/admin">
              Login
            </Link>
          </div>
          <Link to="/admin/login/forgot" className="for-pass">
            Forgot Password?
          </Link>
        </form>
      </div>
    </div>
  );
}
