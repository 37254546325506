import React, { useState } from 'react'
import toursStyles from "./toursCard.module.css"
import "../../dashboardIndex.css"
import { BsTrashFill } from 'react-icons/bs';
import { BsPencilFill } from 'react-icons/bs';
import { BsClock } from "react-icons/bs";
import { BsPeopleFill } from "react-icons/bs";
import { PiMapPinFill } from "react-icons/pi";


const ToursCard = ({ children, image, price, starting, ending, rating, review, day, night, people, location, description }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleDescription = () => {
        setIsExpanded(!isExpanded);
    };

    const truncatedDescription = description.length > 80 ? description.slice(0, 80) + "..." : description;

    return (
        <div className={toursStyles.card}>
            <div className={toursStyles.imageSection} style={{ backgroundImage: `url(${image})` }}>
                <div className={toursStyles.price}>{price} <sub>/ per person</sub></div>
                <div className={toursStyles.meta}>
                    <span>
                        <BsClock />
                        {day}D/{night}N
                    </span>
                    <span>
                        <BsPeopleFill />
                        People:{people}
                    </span>
                    <span>
                        <PiMapPinFill />
                        {location}
                    </span>
                </div>
            </div>
            <div className={toursStyles.contentSection}>
                <h4>{children}</h4>
                <div className={toursStyles.startingEnding}>
                    <p><span>Starting Point: </span>{starting}.</p>
                    <p><span>Ending Point: </span>{ending}.</p>
                </div>

                <div className={toursStyles.ratingStart} title="Rated 5 out of 5">
                    <span style={{ width: rating }}></span>
                </div>
                <p>{isExpanded ? description : truncatedDescription}
                    {description.length > 100 && (
                        <span onClick={toggleDescription} className={toursStyles.toggleButton}>
                            {isExpanded ? ' See less' : ' See more'}
                        </span>
                    )}</p>
                <span>
                    <p className={toursStyles.review}>{review} reviews</p>
                    <div className={toursStyles.actionSection}>
                        <span className='transitionAnimation'><BsPencilFill /> Edit</span>
                        <span className='transitionAnimation'><BsTrashFill /> Delete</span>
                    </div>
                </span>
            </div>
        </div>
    )
}

export default ToursCard
