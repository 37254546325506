import "./App.css";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Destination from "./pages/destination";
import Tours from "./pages/tours";
import About from "./pages/about";
import Error404 from "./pages/404";
import PackageDetail from "./pages/PackageDetail";
import Blog from "./pages/blog";
import Contact from "./pages/contact";
import Login from "./pages/login";
import ForgotPassword from "./pages/ForgotPassword";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import Header from "./components/header";
import Footer from './components/footer';
import ScrollToTopButton from './components/shared/ScrollToTopButton';
import ComingSoon from "./pages/comingSoon";
import DashboardUsers from './Admin/dashboard/dashboardUsers/dashboardUsers';
import DashboardDestinations from './Admin/dashboard/dashboardDestinations/dashboardDestinations';
import DashboardTours from './Admin/dashboard/dashboardTours/dashboardTours';
import DashboardPackages from './Admin/dashboard/dashboardPackages/dashboardPackages';
import DashboardMain from "./Admin/dashboard/dashboardMain/dashboardMain";
import ProtectedRoute from "./components/protectedRoute";
import DashboardWishlist from "./Admin/dashboard/dashboardWishlist/dashboardWishlist";
import DashboardBookingAndEnquiry from './Admin/dashboard/dashboardBookingAndEnquiry/dashboardBookingAndEnquiry';
import DashboardComments from "./Admin/dashboard/dashboardComments/dashboardComments";

function App() {

  const UserType = {
    Admin: "admin",
    Client: "client"
  }

  useEffect(() => {
    // window.localStorage.setItem("loggedIn", false);
    // window.localStorage.setItem("userType", UserType.Client);

    window.localStorage.setItem("loggedIn", true);
    window.localStorage.setItem("userType", UserType.Admin);

  });

  const isLoggedIn = window.localStorage.getItem("loggedIn");
  const userType = window.localStorage.getItem("userType");

  return (
    <>
      <Header isLoggedIn={isLoggedIn} userType={userType} />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/destination" element={<Destination />} />
        <Route path="/tours" element={<Tours />} />
        <Route path="/about" element={<About />} />
        <Route path="/tours/package-detail" element={<PackageDetail />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="*" element={<Error404 />} />

        <Route path="/admin/login" element={<Login />} />

        <Route element={<ProtectedRoute />}>
          <Route path="/admin" element={<DashboardMain />} />
          <Route path="/admin/login" element={<Login />} />
          <Route path="/admin/login/forgot" element={<ForgotPassword />} />
          <Route path="/admin/users" element={<DashboardUsers />} />
          <Route path="/admin/destination" element={<DashboardDestinations />} />
          <Route path="/admin/tours" element={<DashboardTours />} />
          <Route path="/admin/packages" element={<DashboardPackages />} />
          <Route path="/admin/bookingAndEnquiry" element={<DashboardBookingAndEnquiry />} />
          <Route path="/admin/wishlist" element={<DashboardWishlist />} />
          <Route path="/admin/comments" element={<DashboardComments />} />
        </Route>

      </Routes>
      <ScrollToTopButton isLoggedIn={isLoggedIn} userType={userType} />
      <Footer isLoggedIn={isLoggedIn} userType={userType} />
    </>


  );
}

export default App;
