import React, { useState } from "react";
import logo2 from "../images/Album-Travel-Logo.png";
import CustomModal from "./shared/CustomModal";
import SocialMadia from "./socialMadia";

import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { ADDRESS, INFO_MAILE, PHONE1, PHONE2 } from "../globals";
import AdminHeader from "../Admin/components/admin-header/adminHeader";

function Header({ isLoggedIn, userType }) {
  const CustomLink = ({ to, children, ...props }) => {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });
    return (
      <li className={isActive ? "active" : ""}>
        <Link to={to} {...props}>
          {children}
        </Link>
      </li>
    );
  };

  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleOpen = () => {
    setOpenModal(true);
  };
  const handleClick = (event) => {
    const ulMenu = document.querySelector("#masthead > div.mobile-menu-container > div > nav > ul");
    // Retrieve the <nav> element from the parent node of the clicked element
    const ulElement = event.target.parentNode.parentNode.querySelector("nav");


    // Check if the <nav> element exists
    if (ulElement) {
      // Toggle the "d-none" className of the <nav> element
      ulElement.classList.toggle("d-none");
      ulMenu.classList.remove("d-none");
      ulMenu.classList.add("d-block");
    }

  };

  const handleClickOnMenu = (event) => {
    const ulMenu = document.querySelector("#masthead > div.mobile-menu-container > div > nav > ul");
    ulMenu.classList.remove("d-block");
    ulMenu.classList.add("d-none");

  }

  return (
    <>
      <header id="masthead" className={`site-header header-primary ${isLoggedIn === "true" && userType === "admin" ? "force-display-none" : ""}`}>
        <div className="top-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-8 d-none d-lg-block">
                <div className="header-contact-info">
                  <ul>
                    <li>
                      <Link to="#">
                        <i className="fas fa-phone-alt"></i>
                        {PHONE1}, {PHONE2}
                      </Link>
                    </li>
                    <li>
                      <Link to="mailto:info@Travel.com">
                        <i className="fas fa-envelope"></i>
                        {INFO_MAILE}
                      </Link>
                    </li>
                    <li>
                      <i className="fas fa-map-marker-alt"></i>
                      {ADDRESS}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 d-flex justify-content-lg-end justify-content-between ">
                <SocialMadia />
                <div className="header-search-icon">
                  <button className="search-icon" onClick={() => handleOpen()}>
                    <i className="fas fa-search"></i>
                  </button>
                  <CustomModal openModal={openModal} handleClose={handleClose}>
                    <input
                      className="searh-input"
                      placeholder="Enter your text..."
                    />
                  </CustomModal>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-header">
          <div className="container-fluid px-lg-5 d-flex justify-content-between align-items-center">
            <div className="site-identity">
              <h1 className="site-title">
                <Link to="/">
                  <img
                    className="white-logo"
                    src={logo2}
                    alt="logo"
                    width="130px"
                  />
                  <img className="black-logo" src={logo2} alt="logo" />
                </Link>
              </h1>
            </div>
            <div className="main-navigation d-none d-lg-block">
              <nav id="navigation" className="navigation">
                <ul>
                  <CustomLink to="/home">Home</CustomLink>
                  <CustomLink to="/destination">Destinations</CustomLink>
                  <CustomLink to="/tours">Tours</CustomLink>
                  <CustomLink to="/about">About</CustomLink>
                  <CustomLink to="/blog">Blog</CustomLink>
                </ul>
              </nav>
            </div>
            <div className="header-btn">
              <Link to="/contact" className="button-primary">
                BOOK NOW
              </Link>
            </div>
          </div>
        </div>
        <div className="mobile-menu-container">
          <div className="slicknav_menu">
            <a
              aria-haspopup="true"
              role="button"
              tabindex="0"
              className="slicknav_btn slicknav_collapsed"
              onClick={(e) => handleClick(e)}
            >
              <span
                className="slicknav_menutxt"
                onClick={(e) => handleClick(e)}
              >
                Menu
              </span>
              <span className="slicknav_icon" onClick={(e) => handleClick(e)}>
                <span className="slicknav_icon-bar"></span>
                <span
                  className="slicknav_icon-bar"
                  onClick={(e) => handleClick(e)}
                ></span>
                <span
                  onClick={(e) => handleClick(e)}
                  className="slicknav_icon-bar"
                ></span>
              </span>
            </a>
            <nav
              className="slicknav_nav slicknav_hidden d-lg-none d-none"
              aria-hidden="true"
              role="menu"
            >
              <ul onClick={(e) => handleClickOnMenu(e)}>
                <li>
                  <Link to="/" role="menuitem">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/destination" role="menuitem">
                    Destinations
                  </Link>
                </li>
                <li>
                  <Link to="/tours" role="menuitem">
                    Tours
                  </Link>
                </li>
                <li>
                  <Link to="/about" role="menuitem">
                    About
                  </Link>
                </li>

                <li>
                  <Link to="/blog" role="menuitem">
                    Blog
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>

      { (isLoggedIn === "true" && userType === "admin") ? <AdminHeader /> : <></> }

    </>
  );
}

export default Header;
